export class PeriodoEscolar {
  IdPeriodoEscolar: string;
  ClaveTipoPeriodo: string;
  ClavePeriodo: string;
  Descripcion: string;
  FechaInicioOficial: Date;
  FechaTerminacionOficial: Date;
  Activo: boolean;

  constructor(PE) {
    if (PE) {
      this.IdPeriodoEscolar = PE.IdPeriodoEscolar;
      this.ClaveTipoPeriodo = PE.ClaveTipoPeriodo;
      this.ClavePeriodo = PE.ClavePeriodo;
      this.Descripcion = PE.Descripcion;
      this.FechaInicioOficial = new Date(PE.FechaInicioOficial);
      this.FechaTerminacionOficial = new Date(PE.FechaTerminacionOficial);
      this.Activo = PE.Activo;
    }
  }
}
