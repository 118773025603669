import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingComponent } from 'src/app/components/ui/loading/loading.component';
import { UploadingComponent } from 'src/app/components/ui/uploading/uploading.component';
import { MatProgressBarModule } from '@angular/material';


@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  declarations: [
    LoadingComponent,
    UploadingComponent
  ],
  exports: [
    LoadingComponent,
    UploadingComponent
  ]
})
export class LoadingComponentsModule { }
