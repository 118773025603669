export class AccesoEstudianteDTO
{
    GrupoId: number;
    GrupoNombre: string;
    UnidadAprendizaje: string;
    Semana: string;
    FechaInicio: Date;
    FechaFin: Date;
    Total: number;
    Dia: number;
    TotalAlumnos: number;
    Porcentaje: string;
    Orden: number;

    constructor(A)
    {
        if(A)
        {
            this.Dia = A.Dia;
            this.FechaFin = A.FechaFin;   
            this.FechaInicio = A.FechaInicio;
            this.GrupoId = A.GrupoId;
            this.GrupoNombre = A.GrupoNombre;
            this.Porcentaje = A.Porcentaje;
            this.Semana = A.Semana;
            this.Total = A.Total;
            this.TotalAlumnos = A.TotalAlumnos;
            this.UnidadAprendizaje = A.UnidadAprendizaje;
            this.Orden = A.Orden;
        }
    }

}