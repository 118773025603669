export class CompetenciaGenerica {
    CompetenciaGenericaId: number;
    Descripcion: string;
    Numero: number;
    Atributos: AtributoCompetenciaGenerica[] = [];
    Estado: boolean;

    constructor(C) {
        if (C) {
            this.CompetenciaGenericaId = C.CompetenciaGenericaId;
            this.Descripcion = C.Descripcion;
            this.Numero = C.Numero;
            this.Atributos = C.Atributos.map( A => new AtributoCompetenciaGenerica(A));
            this.Estado = C.Estado;
        }
    }
}

export class AtributoCompetenciaGenerica {
    AtributoCompetenciaGenericaId: number;
    CompetenciaGenericaId: number;
    Descripcion: string;
    RasgoId: number;
    Rasgo: string;
    Estado: boolean;

    constructor(ACG) {
        if (ACG) {
            this.AtributoCompetenciaGenericaId = ACG.AtributoCompetenciaGenericaId;
            this.CompetenciaGenericaId = ACG.CompetenciaGenericaId;
            this.Descripcion = ACG.Descripcion;
            this.RasgoId = ACG.RasgoId;
            this.Rasgo = ACG.Rasgo;
            this.Estado = ACG.Estado;
        }
    }
}
