import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecursosRoutes } from './recursos.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyMaterialModule } from '../material.module';

import { RecursosComponent } from './recursos.component';

import { LoadingComponentsModule } from '../shared/LoadingComponents.module';
import { FroalaEditorModule } from 'angular-froala-wysiwyg';
import { SharedWidgetModule } from '../shared/widgets.module';
import { FileComponentsModule } from '../shared/FileComponents.module';
import { PipesModule } from '../shared/Pipes.module';
import { RecursosComponentsModule } from './recursos.components.module';




@NgModule({
  imports: [
    CommonModule,
    RecursosComponentsModule,
    RecursosRoutes,
    FormsModule,
    ReactiveFormsModule,
    LoadingComponentsModule,
    FileComponentsModule,
    MyMaterialModule,
    SharedWidgetModule,
    PipesModule,
    //FroalaEditorModule
  ],
  declarations: [
    RecursosComponent
  ],

})
export class RecursosModule { }
