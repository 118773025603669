export class ParticipacionForoProfesorDTO
{
    NombreMateria: string;
    NombreForo: string;
    NombreTema: string;
    GrupoNombre: string;
    NombreProfesor: string;
    Comentarios: string;
    FechaInicio: string;
    FechaFin: string;
    Duracion: number;

    constructor(P)
    {
        if(P)
        {
            this.NombreMateria = P.NombreMateria;
            this.NombreForo = P.NombreForo;
            this.NombreTema = P.NombreTema;
            this.GrupoNombre = P.GrupoNombre;
            this.NombreProfesor = P.NombreProfesor;
            this.Comentarios = P.Comentarios;
            let Fi = new Date(P.FechaInicio);
            let Ff = new Date(P.FechaFin);
            this.FechaInicio =  Fi.getFullYear() +
            "-" +
            (Fi.getMonth() < 10 ? "0" : "") +
            (Fi.getMonth() + 1) +
            "-" +
            (Fi.getDate() < 10 ? "0" : "") +
            Fi.getDate();
            this.FechaFin =  Ff.getFullYear() +
            "-" +
            (Ff.getMonth() < 10 ? "0" : "") +
            (Ff.getMonth() + 1) +
            "-" +
            (Ff.getDate() < 10 ? "0" : "") +
            Ff.getDate();
            this.Duracion = P.Duracion;
        }
    }
}