import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyMaterialModule } from './material.module';
import { CommonModule } from '@angular/common';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MyMaterialModule,
    //SharedWidgetModule,
  ],
  declarations: [
  ],
  entryComponents: [ 
  ],    
  exports: [
  ]
})
export class SharedModule { }
