export class ParticipacionForoAlumnoDTO{
    NombreMateria: string;
    ForoNombre: string;
    NombreTema: string;
    GrupoNombre: string;
    NombreProfesor: string;
    FechaInicio: string;
    FechaFin: string;
    Participaciones: number;
    AlumnosTotal: number;
    Porcentaje: string;
    
    constructor(P)
    {
        if(P)
        {
            this.NombreMateria = P.NombreMateria;
            this.ForoNombre = P.ForoNombre;
            this.NombreTema = P.NombreTema;
            this.GrupoNombre = P.GrupoNombre;
            this.NombreProfesor = P.NombreProfesor;
            let Fi = new Date(P.FechaInicio);
            let Ff = new Date(P.FechaFin);
            this.FechaInicio =  Fi.getFullYear() +
            "-" +
            (Fi.getMonth() < 10 ? "0" : "") +
            (Fi.getMonth() + 1) +
            "-" +
            (Fi.getDate() < 10 ? "0" : "") +
            Fi.getDate();
            this.FechaFin =  Ff.getFullYear() +
            "-" +
            (Ff.getMonth() < 10 ? "0" : "") +
            (Ff.getMonth() + 1) +
            "-" +
            (Ff.getDate() < 10 ? "0" : "") +
            Ff.getDate();
            this.Participaciones = P.Participaciones;
            this.AlumnosTotal = P.AlumnosTotal;
            this.Porcentaje = P.Porcentaje;
        }
    }
}