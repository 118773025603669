export class AccesoProfesorDTO
{
    GrupoId: number;
    GrupoNombre: string;
    UnidadAprendizaje: string;
    Profesor: string;
    Semana: string;
    FechaInicio: Date;
    FechaFin: Date;
    Total: number;
    Orden: number;

    constructor(A)
    {
        this.GrupoId = A.GrupoId;
        this.GrupoNombre = A.GrupoNombre;
        this.UnidadAprendizaje = A.UnidadAprendizaje;
        this.Profesor = A.Profesor;
        this.Semana = A.Semana;
        this.FechaInicio = A.FechaInicio;
        this.Total = A.Total;
        this.Orden = A.Orden;
    }
}