import { Injectable } from '@angular/core';
import { ApiHttpService } from '../api-http/ApiHttp.service';


@Injectable({
  providedIn: 'root'
})
export class desempenoService {
  public Dominio = 'ReportesNEyM';
  constructor(
    private API: ApiHttpService
    
  ) {}


  public ConsultarReporteGruposAccesosAlumno(_ClavePeriodo: string, _ClaveDependencia: string, _ClaveUnidad : string, _UnidadAprendizajeId: number, _ClaveModalidad: string) {
    return this.API.POST(this.Dominio, 'ConsultarReporteGruposAccesosAlumno', {
      ClavePeriodo: _ClavePeriodo,
      ClaveDependencia: _ClaveDependencia,
      ClaveUnidad: _ClaveUnidad,
      UnidadAprendizajeId: _UnidadAprendizajeId,
      ClaveModalidad: _ClaveModalidad
    });
  }
  public ConsultarReporteGruposAccesosProfesor(_ClavePeriodo: string, _ClaveDependencia: string, _ClaveUnidad : string, _UnidadAprendizajeId: number, _ClaveModalidad: string) {
    return this.API.POST(this.Dominio, 'ConsultarReporteGruposAccesosProfesor', {
      ClavePeriodo: _ClavePeriodo,
      ClaveDependencia: _ClaveDependencia,
      ClaveUnidad: _ClaveUnidad,
      UnidadAprendizajeId: _UnidadAprendizajeId,
      ClaveModalidad: _ClaveModalidad
    });
  }
  public ConsultarReporteNEyMSeguimientoExamenes(_ClavePeriodo: string, _ClaveDependencia: string, _ClaveUnidad : string, _UnidadAprendizajeId: number, _ClaveModalidad: string, _Pagina: number, 
    _Paginacion: number) {
    return this.API.POST(this.Dominio, 'ConsultarReporteNEyMSeguimientoExamenes', {
      ClavePeriodo: _ClavePeriodo,
      ClaveDependencia: _ClaveDependencia,
      ClaveUnidad: _ClaveUnidad,
      UnidadAprendizajeId: _UnidadAprendizajeId,
      ClaveModalidad: _ClaveModalidad,
      Pagina: _Pagina,
      Paginacion: _Paginacion
    });
  }
  public ConsultarReporteRubricaCompetenciasForosAlumno(_ClavePeriodo: string, _ClaveDependencia: string, _ClaveUnidad : string, _UnidadAprendizajeId: number, _ClaveModalidad: string, _Pagina: number, 
    _Paginacion: number) {
    return this.API.POST(this.Dominio, 'ConsultarReporteRubricaCompetenciasForosAlumno', {
      ClavePeriodo: _ClavePeriodo,
      ClaveDependencia: _ClaveDependencia,
      ClaveUnidad: _ClaveUnidad,
      UnidadAprendizajeId: _UnidadAprendizajeId,
      ClaveModalidad: _ClaveModalidad,
      Pagina: _Pagina,
      Paginacion: _Paginacion
    });
  }
  public ConsultarReporteRubricaCompetenciasForosProfesor(_ClavePeriodo: string, _ClaveDependencia: string, _ClaveUnidad : string, _UnidadAprendizajeId: number, _ClaveModalidad: string, _Pagina: number, 
    _Paginacion: number) {
    return this.API.POST(this.Dominio, 'ConsultarReporteRubricaCompetenciasForosProfesor', {
      ClavePeriodo: _ClavePeriodo,
      ClaveDependencia: _ClaveDependencia,
      ClaveUnidad: _ClaveUnidad,
      UnidadAprendizajeId: _UnidadAprendizajeId,
      ClaveModalidad: _ClaveModalidad,
      Pagina: _Pagina,
      Paginacion: _Paginacion
    });
  }
}
