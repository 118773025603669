export class SeguimientoExamenDTO{
    Renglon: number;
    NombreMateria: string;
    ExamenNombre: string;
    Fechainicio: string;
    GrupoNombre: string;
    Estudiantes: number;
    Habilitado: number;
    Contesto: number;
    Nocontesto: number;
    Porcentaje: string;

    constructor(S)
    {
        this.Renglon = S.Renglon;
        this.NombreMateria = S.NombreMateria;
        this.ExamenNombre = S.ExamenNombre;
        this.GrupoNombre = S.GrupoNombre;
        this.Estudiantes = S.Estudiantes;
        this.Habilitado = S.Habilitado;
        this.Contesto = S.Contesto;
        this.Nocontesto = S.Nocontesto;
        this.Porcentaje = S.Porcentaje;
        let Fi = new Date(S.Fechainicio);
            
            this.Fechainicio =  Fi.getFullYear() +
            "-" +
            (Fi.getMonth() < 10 ? "0" : "") +
            (Fi.getMonth() + 1) +
            "-" +
            (Fi.getDate() < 10 ? "0" : "") +
            Fi.getDate();

    }
}