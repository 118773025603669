import { Routes, RouterModule } from '@angular/router';
import { RecursosDefaultComponent } from 'src/app/components/recursos/recursos/recursos-default/recursos-default.component';

const routes: Routes = [
  {
    path: '',
    component: RecursosDefaultComponent,
  },
];

export const RecursosRoutes = RouterModule.forChild(routes);
