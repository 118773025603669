export class ProfesorSIASE {
  _NombreCompleto: string;
  IdProfesor: string;
  ClaveEmpleado: string;
  Nombre:string;
  ApellidoPaterno: string;
  ApellidoMaterno: string;
  CURP: string;
  Correo: string;
  constructor(P) {
    this.IdProfesor = P.IdProfesor;
    this.ClaveEmpleado = P.ClaveEmpleado;
    this.Nombre = P.Nombre;
    this.ApellidoPaterno = P.ApellidoPaterno;
    this.ApellidoMaterno = P.ApellidoMaterno;
    this.CURP = P.CURP;
    this.Correo = P.Correo;
  }

  public NombreCompleto(Formato?: string) {
    switch(Formato) {
      case  'NPM': return this.Nombre + ' ' + this.ApellidoPaterno + ' ' + this.ApellidoMaterno;
      case  'PMN': return this.ApellidoPaterno + ' ' + this.ApellidoMaterno + ' ' + this.Nombre;
      case  'PN':  return this.ApellidoPaterno + ' ' + this.Nombre;
      default:  return this.Nombre + ' ' + this.ApellidoPaterno + ' ' + this.ApellidoMaterno;
    }
  }
}
