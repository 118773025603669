import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { CONSTANTS } from './../constants';
import { GeneralService } from './general.service';
import { PerfilService } from './perfil.service';
import { SesionService } from 'src/app/services/Sesion/Sesion.service';

@Injectable({
  providedIn: 'root'
})
export class AvisosService {
  private HEADER;

  constructor(
    private SesionSVC: SesionService,
    private http: HttpClient,
    private general_service: GeneralService,
    private perfil_service: PerfilService
  ) {
    console.log(this.perfil_service.Perfil);
    console.log(this.SesionSVC.Perfil);
    this.HEADER = {
      headers: new HttpHeaders()
      .append("Token", this.SesionSVC.Token)
      .append("AreaAcademicaId", this.SesionSVC.Perfil.AreaAcademica.AreaAcademicaId.toString())
      .append("RolId", this.SesionSVC.Perfil.Rol.RolId.toString())
    }
  }

  private updateHeader(){
    this.HEADER = this.general_service.getUpdateHeader();
  }

  /****************************************** Aviso *********************************************/
  public getAvisos(_CursoId, _Asignados){
    let data = {
        CursoId: _CursoId,
        SoloAsignadas: _Asignados
    };
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Aviso/ConsultarAvisos",data, this.HEADER )
    .pipe(
        map(data => this.general_service.handleResult(data)),
        catchError(error => this.general_service.handleError(error))
    );
  }

  public getAviso(_AvisoId){
    let data = { AvisoId: _AvisoId };
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Aviso/ConsultarAviso",data, this.HEADER )
    .pipe(
        map(data => this.general_service.handleResult(data)),
        catchError(error => this.general_service.handleError(error))
    );
  }

  public UpdateAviso(_Aviso){
    let data = { Aviso: _Aviso };
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Aviso/ActualizarAviso",data, this.HEADER )
    .pipe(
        map(data => this.general_service.handleResult(data)),
        catchError(error => this.general_service.handleError(error))
    );
  }

  public CopiarAviso(_Aviso, _CursoId, _AvisoOrigenId){
    let data = {
      Aviso: _Aviso,
      CursoId: _CursoId,
      AvisoOrigenId: _AvisoOrigenId
    };
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Aviso/Copiaraviso",data, this.HEADER )
    .pipe(
        map(data => this.general_service.handleResult(data)),
        catchError(error => this.general_service.handleError(error))
    );
  }

  /******************************************* Destino *********************************************/

}
